import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../context/user"

export default function Redirect(props) {
    const navigate = useNavigate()
    const { SetUser } = useContext(UserContext)

    useEffect(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('projectToken')
        SetUser({})
        navigate('/')
    }, [navigate, SetUser])

    return (
        <></>
    )
}