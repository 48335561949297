import { useState } from 'react';
import styled from 'styled-components';
import { instance, errorHandling } from '../../../src/instance';
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';

import { HorizontalLayout, ColorBtn, FormInput } from 'opize-components'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Form = styled.form`
    width: 100%;
`

const Inputs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Btns = styled.div`
    margin-top: 20px;
    width: fit-content;
    float: right;
`

export default function Create(props) {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            code: "",
            url: "",
            icon: "",
            desc: "",
            guideUrl: ""
        }
    });

    const onSubmit = async (data) => {
        if (!isLoading) {
            try {
                setLoading(true)
                await instance.post(`/embed`, {
                    name: data.name,
                    code: data.code,
                    url: data.url,
                    icon: data.icon,
                    desc: data.desc,
                    guideUrl: data.guideUrl,
                });
                setLoading(false)
                toast.info('임베드를 생성했습니다.')
                navigate('/admin/embed')
            } catch (err) {
                setLoading(false)
                errorHandling(err, t)
            }
        }
    };

    return (
        <HorizontalLayout label={'임베드 추가'} marginTop={16}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Inputs>
                    <Controller
                        name="name" 
                        control={control}
                        rules={{required: 'name을 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'name'} ref={null} error={errors.name} type="text" autoComplete="off"/>}
                    />
                    <Controller
                        name="code" 
                        control={control}
                        rules={{required: 'code를 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'code'} ref={null} error={errors.code} type="text" autoComplete="off" />}
                    />
                    <Controller
                        name="url" 
                        control={control}
                        rules={{required: 'url을 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'url'} ref={null} error={errors.url} type="text" autoComplete="off" />}
                    />
                    <Controller
                        name="icon" 
                        control={control}
                        rules={{required: 'icon을 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'icon'} ref={null} error={errors.icon} type="text" autoComplete="off" />}
                    />
                    <Controller
                        name="desc" 
                        control={control}
                        rules={{required: 'desc을 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'desc'} ref={null} error={errors.desc} type="text" autoComplete="off" />}
                    />
                    <Controller
                        name="guideUrl" 
                        control={control}
                        rules={{required: 'guideUrl을 입력해주세요.'}}
                        render={({field}) => <FormInput {...field} label={'guideUrl'} ref={null} error={errors.guideUrl} type="text" autoComplete="off" />}
                    />
                </Inputs>
                <Btns>
                    <ColorBtn type="submit" isLoading={isLoading} label={'임베드 추가'} />
                </Btns>
            </Form>
        </HorizontalLayout>
    )
}