import { Notion } from "../../../components/make/notion";
import {  Btn, HorizontalLayout, InputFile, Input } from 'opize-components';
import styled from "styled-components";
import { Resizable } from "re-resizable";
import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { instance, instanceWrap } from "../../../src/instance";
import PlaceHolderImage from '../../../assets/imageLink/placeholder.png'
import { UserContext } from '../../../context/user'

import { useTranslation } from "react-i18next";
import axios from "axios";


const Divver = styled.div`
    width: 100%;
`

const ImagePreviewDivver = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    transition: 200ms;
    background-color: ${props => props.darkMode ? '#2f3437' : 'transparent'};
    border-radius: 8px;
`

const ImagePreviewImg = styled.img`
    width: 100%;
    user-select: none;
`

const ImageHandleDivver = styled.div`
    user-select: none;
    position: absolute;
    ${props => props.direction}: -2px;
    height: 100%;
    display: flex;
    align-items: center;
`

const ImageHandle = styled.div`
    opacity: 0;
    transition: opacity 300ms ease-in 0s;
    border-radius: 20px;
    background: rgba(15, 15, 15, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.9);
    width: 6px;
    height: 48px;
    max-height: 50%;
    transition: 200ms;
`

const ImageResize = styled(Resizable)`
    &:hover {
        ${ImageHandle} {
            opacity: 1;
        }
    }
`

const Label = styled.p`
    font-size: 14px;
    color: var(--grey6);
`

const ImagePreview = (props) => {
    return (
        <ImagePreviewDivver darkMode={props.darkMode}>
            <ImageResize
                resizeRatio={2}
                enable={{top:false, right:true, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
                snapGap={10}
                maxWidth={720}
                minWidth={240}
            >
                <ImageHandleDivver direction={'left'}>
                    <ImageHandle />
                </ImageHandleDivver>

                <ImageHandleDivver direction={'right'}>
                    <ImageHandle />
                </ImageHandleDivver>

                <a href={props.to || '/'} target={'_blank'} rel="noreferrer">
                    <ImagePreviewImg src={props.img || PlaceHolderImage} alt="링크" />
                </a>
                

            </ImageResize>
        </ImagePreviewDivver>
    )
}

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 8px;
    align-items: flex-end;
    
    border-top: solid 1px var(--grey3);
    border-bottom: solid 1px var(--grey3);
    padding: 24px 0;
`

const Result = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 16px;
`

function MakeComponent (props) {
    const [ imgMode, setImgMode ] = useState('link')
    const [ img, setImg ] = useState('')
    const [ to, setTo ] = useState('')
    const [ target, setTarget ] = useState('_blank')
    const [ result, setResult ] = useState('')
    const [ darkMode, setDarkMode ] = useState(false)
    const { t } = useTranslation('translation')
    const { user } = useContext(UserContext)

    const uploadImg = async (e) => {
        if (e.size > 5242880) {
            toast.info('파일 크기가 너무 커요. 5Mb 까지만 업로드 할 수 있어요.')
            return
        }

        if (e.type.split('/')[0] !== 'image') {
            toast.info('이미지 파일이 아니에요')
            return
        }

        console.log(e)

        const res = await instanceWrap(() => instance.post('/user-embed/file/image', {
            path: e.name,
            fileType: e.type
        }), t)

        await axios.put(res.data.uploadURL, e, {
            headers: {
                "Content-Type": e.type
            }
        })

        setImg(`${process.env.REACT_APP_STATIC_SERVER}/${res.data.filePath}`)
        console.log(res.data)
    }

    useEffect(() => {
        if (img && to) {
            setResult(encodeURI(`${process.env.REACT_APP_HOST}/imageLink.html?theme=${darkMode ? 'black' : 'white'}&image=${img}&link=${to}&target=${target}`))
        } else {
            setResult(``)
        }
        
    }, [darkMode, img, to, target])

    const copyLink = () => {
        if (!result) return
        toast.info('링크를 복사했어요.')
        navigator.clipboard.writeText(result);
    }

    const ChangeImgMode = () => {
        if (!user.isVerified) {
            toast.info('이미지를 업로드하려면 먼저 로그인해주세요!')
            return
        }
        setImgMode(imgMode === 'link' ? 'upload' : 'link')
    }

    return (
        <Divver>
            <ImagePreview to={to} img={img} darkMode={darkMode} />
            <Inputs>

                <HorizontalLayout label="이미지">
                    {/* <Btn
                        label={imgMode === 'link' ? '링크' : '이미지 업로드'}
                        onClick={ChangeImgMode}
                    /> */}
                    {/* {imgMode === 'upload' && <InputFile setUploadFile={uploadImg}/>} */}
                    {imgMode === 'link' && <Input onChange={(e) => setImg(e.target.value)} value={img} placeholder='이미지 파일 주소' />}
                </HorizontalLayout>

                <HorizontalLayout label="이동할 링크">
                    <Input onChange={e => setTo(e.target.value)} placeholder='이동할 링크'/>
                </HorizontalLayout>

                <HorizontalLayout label="배경 색">
                    <Btn
                        label={darkMode ? '🌜 어두운 배경' : '🌞 밝은 배경'}
                        backgroundColor={darkMode ? 'var(--grey9)' : 'var(--grey2)'}
                        backgroundColorHover={darkMode ? 'var(--grey8)' : 'var(--grey3)'}
                        color={darkMode ? 'var(--grey1)' : 'var(--grey9)'}
                        onClick={() => setDarkMode(!darkMode)} />
                </HorizontalLayout>

                <HorizontalLayout label="클릭했을 때">
                    <Btn
                        label={target === '_blank' ? '새 창에서 열기' : '현재 창에서 열기'}
                        onClick={() => setTarget(target === '_blank' ? '_parent' : '_blank')} />
                    <Label>미리보기에서는 적용되지 않습니다.</Label>
                </HorizontalLayout>

                <Result>
                    <Input readOnly value={result} />
                    <Btn label="복사" onClick={copyLink}
                        backgroundColor={result ? 'var(--teal5)' : 'var(--grey5)'}
                        backgroundColorHover={result ? 'var(--teal6)' : 'var(--grey5)'}
                        color={result ? 'var(--grey1)' : 'var(--grey9)'}
                    />
                </Result>
            </Inputs>
        </Divver>
    )
}

export default function Make(props) {
    
    return (
        <>
            <Notion
                id="3490987427a04bf98691704575de2a70"
                make={<MakeComponent />}
            />
        </>
    )
}