

export default function Dashboard(props) {

    return (
        <>
            대시보드
            <br />
            <a href="notion://www.notion.so/hyunsdev/b7a9567e8aad48b5ab36dfc31f8eb085">임베드 로그</a>
        </>
    )
}