import React from 'react'
import {  Route, Routes } from 'react-router-dom';
import { HeaderWrapper } from '../../components';

import Board from './board';
import ImageLink from './opize/imageLink';
import Html from './opize/html';

export default function Router() {
  return (
    <>
      <HeaderWrapper />
      <Routes>
          <Route path="/" element={<Board />} />
          <Route path="/imageLink" element={<ImageLink />} />
          <Route path="/html" element={<Html />} />
      </Routes>
    </>
  );
};
