import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {instance, errorHandling} from '../../../src/instance';
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CodeBlock, HorizontalLayout, ColorBtn, FormInput,Btn } from 'opize-components'


const Divver = styled.div`
    margin-top: 16px;
`

const Form = styled.form`
    width: 100%;
`

const Inputs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Btns = styled.div`
    margin-top: 20px;
    width: fit-content;
    float: right;
    display: flex;
    gap: 8px;
`

export default function Create(props) {
    const { t } = useTranslation('translation')
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [ embedCode, setEmbedCode ] = useState(searchParams.get('embedCode'))
    const [ originalEmbed, setEmbed ] = useState({})
    const { control, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            code: "",
            url: "",
            icon: "",
            desc: "",
            guideUrl: "",
        }
    });
    const navigate = useNavigate()

    useEffect(() => {
        setEmbedCode(searchParams.get('embedCode'))
    }, [searchParams, setEmbedCode])

    useEffect(() => {
        (async () => {
            if (embedCode) {
                try {
                    const res = await instance.get(`/embed/${embedCode}`)
                    reset({
                        name: res.data.name,
                        url: res.data.url,
                        icon: res.data.icon,
                        desc: res.data.desc,
                        guideUrl: res.data.guideUrl,
                    })
                    setEmbed(res.data)
                } catch (err) {
                    console.error(err)
                }
            } else {
                navigate('/admin/embed')
                toast.info('리스트에서 편집할 임베드를 선택하세요.')
            }
        })()
    }, [embedCode, reset, navigate])

    const onSubmit = async (data) => {
        if (!isLoading) {
            try {
                setLoading(true)
                await instance.patch(`/embed/${embedCode}`, {
                    name: data.name,
                    url: data.url,
                    icon: data.icon,
                    desc: data.desc,
                    guideUrl: data.guideUrl,
                });
                setLoading(false)
                toast.info('임베드를 수정했습니다.')
                navigate('/admin/embed')
            } catch (err) {
                setLoading(false)
                errorHandling(err, t)
            }
        }
    };

    const Delete = async (data) => {
        if (!isLoading && window.confirm('정말로 삭제하실 건가요?')) {
            try {
                setLoading(true)
                await instance.delete(`/embed/${embedCode}`);
                setLoading(false)
                toast.info('임베드를 삭제했습니다.')
                navigate('/admin/embed')
            } catch (err) {
                setLoading(false)
                errorHandling(err, t)
            }
        }
    };

    return (
        <Divver>
            <CodeBlock key={originalEmbed.name}
                icon={originalEmbed.icon}
                title={originalEmbed.name}
                subtitle={originalEmbed.code}
                desc={originalEmbed.desc}
            >{JSON.stringify(originalEmbed, null, 4)}</CodeBlock>
            <HorizontalLayout label={'임베드 편집'} marginTop={16}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Inputs>
                        <Controller
                            name="name" 
                            control={control}
                            rules={{required: 'name을 입력해주세요.'}}
                            render={({field}) => <FormInput {...field} label={'name'} ref={null} error={errors.name} type="text" autoComplete="off"/>}
                        />
                        <Controller
                            name="url" 
                            control={control}
                            rules={{required: 'url을 입력해주세요.'}}
                            render={({field}) => <FormInput {...field} label={'url'} ref={null} error={errors.url} type="text" autoComplete="off" />}
                        />
                        <Controller
                            name="icon" 
                            control={control}
                            rules={{required: 'icon을 입력해주세요.'}}
                            render={({field}) => <FormInput {...field} label={'icon'} ref={null} error={errors.icon} type="text" autoComplete="off" />}
                        />
                        <Controller
                            name="desc" 
                            control={control}
                            rules={{required: 'desc을 입력해주세요.'}}
                            render={({field}) => <FormInput {...field} label={'desc'} ref={null} error={errors.desc} type="text" autoComplete="off" />}
                        />
                        <Controller
                            name="guideUrl" 
                            control={control}
                            rules={{required: 'guideUrl을 입력해주세요.'}}
                            render={({field}) => <FormInput {...field} label={'guideUrl'} ref={null} error={errors.guideUrl} type="text" autoComplete="off" />}
                        />
                    </Inputs>
                    <Btns>
                        <Btn onClick={Delete} isLoading={isLoading} label='제거' backgroundColor={'var(--red1)'} backgroundColorHover={'var(--red2)'} color={'var(--red5)'} />
                        <ColorBtn type='submit' isLoading={isLoading} label={'임베드 편집'} />
                    </Btns>
                </Form>
            </HorizontalLayout>
        </Divver>
    )
}