import styled from "styled-components";
import { useState, useEffect } from 'react';

import { instance } from "../../../src/instance";

import { Search, CodeBlock } from 'opize-components'

const Divver = styled.div`
    margin-top: 8px;
`

const Services = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
`

export default function List(props) {
    const [ searchText, setSearchText ] = useState('')
    const [ embeds, setEmbeds ] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const res = await instance.get('/embed')
                console.log(res.data)
                setEmbeds(res.data.embeds)
            } catch (err) {
                console.error(err)
            }
        })()
    }, [setEmbeds])

    return (
        <Divver>
            <Search value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <Services>
                {
                    Object.values(embeds).filter(e => {
                        if (searchText === "") return true
                        if (e.name.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.code.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.desc.toUpperCase().includes(searchText.toUpperCase())) return true
                        return false
                    }).map(e => (
                        <CodeBlock key={e.name}
                            icon={e.icon}
                            title={e.name}
                            subtitle={e.code}
                            desc={e.desc}
                            links={[
                                { text: '편집', to: `/admin/embed/edit?embedCode=${e.code}` }
                            ]}
                        >{JSON.stringify(e, null, 4)}</CodeBlock>
                    ))
                }
            </Services>
        </Divver>
    )
}