/* eslint-disable no-eval */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Collection, CollectionRow, NotionRenderer, Code } from 'react-notion-x'
import { getPageTitle } from 'notion-utils'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { useLocation, Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Back } from 'opize-components'

import { UserContext } from "../../context/user";

const Div = styled.div`
    position: relative;

    .notion .notion-frame .notion-page-no-cover {
        margin-top: 120px !important;
    }

    .notion .notion-header {
        position: fixed;
        top: 4px;
        left: 150px;
        transition: 200ms;
        width: fit-content;
        background: transparent;

        .breadcrumb:hover {
            background: var(--greyPlaceholder)
        }
    }

    .notion .nav-header {
        position: relative;
        width: fit-content;
    }
`

const NotionRendererDiv = styled.div`

`

const CodeX = function(props) {
    const [ Make, setMake ] = useState(<></>)

    useEffect(() => {
        if (props.make) setMake(props.make)
    }, [props.make, setMake])

    switch (props.language) {
        case 'HTML':
            if (props.code.startsWith('<!-- opize -->')) {
                return <div dangerouslySetInnerHTML={{__html: props.code.replaceAll('\\x3', '<')}}></div>
            } else {
                return <Code {...props} />
            }
        case 'JavaScript':
            if (props.code.startsWith('// opize')) {
                const code = props.code.replaceAll('\\n', '\n');
                eval(code)
                return <></>
            } else if (props.code === "<Make />") {
                return Make
            } else {
                return <Code {...props} />
            }
        default:
            return <Code {...props} />
    }
}

const StyledLink = styled(Link)`
    text-decoration: none;
`

const LoadingDiv = styled.div`
    display: flex;
    position: fixed;
    opacity: ${props => props.isLoading ? 1 : 0};
    transition: 200ms;
    left: 30px;
    top: 76px;
    z-index: 10;
    width: 30px;
    height: 30px;
`

const LoadingBox = (props) => {
    return (
        <LoadingDiv isLoading={props.isLoading}>
            <Spinner color={'var(--grey9)'} size={32}/>
        </LoadingDiv>
    )
}

export const Notion = function (props) {
    const [page, setPage] = useState()
    const location = useLocation()
    const [ isLoading, setLoading ] = useState(false)
    const { i18n } = useTranslation('translation')
    const { user } = useContext(UserContext)
    const [ searchParams ] = useSearchParams()

    useEffect(() => {
        (async () => {
            try {
                const pageId = props.id
                // 새로 캐시
                setLoading(true)
                const res = await axios.get(`${process.env.REACT_APP_OPIZE_API_SERVER}/notion?id=${pageId}&project=opize_embed&cache=pass`)
                setPage(res.data)
                setLoading(false)
                
            } catch (err) {
                setLoading(false)
                if (err.response) {
                    console.error(err.response)
                    toast.error(err.message)
                } else {
                    console.error(err)
                    toast.error(err.message)
                }
            }
        })()

        return () => {
            setLoading(false)
        }
    },[location, props.id, i18n.language, setPage])

    useEffect(() => {
        document.title = `${page ? `${getPageTitle(page)} - Opize` : 'Opize'}`
    }, [page])

    return (
        <>
            {searchParams.get('back') === 'true' && <Back />}
            <Div isVerified={user.isVerified}>
                <LoadingBox isLoading={isLoading} />
                <NotionRendererDiv>
                    {page && <NotionRenderer
                        recordMap={page}
                        fullPage
                        darkMode={props.darkMode || false}
                        components={{
                            collection: Collection,
                            collectionRow: CollectionRow,
                            code: (e) => CodeX({
                                ...e,
                                make: props.make
                            }),
                            pageLink: ({
                                href,
                                as,
                                passHref,
                                prefetch,
                                replace,
                                scroll,
                                shallow,
                                locale,
                                ...props
                              }) => (
                                <StyledLink to={href} {...props} />
                              ),
                        }}
                    />}
                </NotionRendererDiv>
            </Div>
        </>
    )
}