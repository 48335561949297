import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { HorizonLNB } from 'opize-components';

import List from './list';
import Create from './create';
import Edit from './edit';

export default function Router() {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <>
            <HorizonLNB exact={true} selected={location.pathname} menu={[
                { id: "/admin/embed", label: '리스트', onClick: () => navigate("/admin/embed") },
                { id: "/admin/embed/create", label: '생성', onClick: () => navigate("/admin/embed/create") },
                { id: "/admin/embed/edit", label: '편집', onClick: () => {} },
            ]} />
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="/create" element={<Create />} />
                <Route path="/edit" element={<Edit />} />
            </Routes>
        </>
    );
};