import axios from "axios";
import { toast } from "react-toastify";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
});
instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('projectToken')}`;

export const opizeInstance = axios.create({
    baseURL: process.env.REACT_APP_OPIZE_API_SERVER,
});
opizeInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

export const instanceWrap = async (instance, t, throwError=false) => {
    try {
        return await instance();
    } catch (err) {
        if (err.response) {
            if (err.response.data.code === "user_not_found") {
                toast.error(t('err_user_not_found'))
            } else if (err.response.data.code === "token_expired") {
                toast.error(t('err_token_expired'))
            } else if (err.response.data.code === "invalid_token") {
                toast.error(t('err_invalid_token'))
            } else if (err.response.data.code === "wrong_password") {
                toast.error(t('err_wrong_password'))
            } else {
                if (throwError) {
                    throw err
                } else {
                    toast.error(err.message)
                    console.error(err.response)
                }
            }
        } else {
            if (throwError) {
                throw err
            } else {
                toast.error(err.message)
                console.error(err)
            }
        }
        return null;
    }
}

export const errorHandling = async (err, t, throwError=false) => {
    if (err.response) {
        if (err.response.data.code === "user_not_found") {
            toast.error(t('err_user_not_found'))
        } else if (err.response.data.code === "token_expired") {
            toast.error(t('err_token_expired'))
        } else if (err.response.data.code === "invalid_token") {
            toast.error(t('err_invalid_token'))
        } else if (err.response.data.code === "wrong_password") {
            toast.error(t('err_wrong_password'))
        } else {
            if (throwError) {
                throw err
            } else {
                toast.error(err.message)
                console.error(err.response)
            }
        }
    } else {
        if (throwError) {
            throw err
        } else {
            toast.error(err.message)
            console.error(err)
        }
    }
    return null;
}