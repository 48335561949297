import { useContext, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { UserContext } from "../../context/user"


export default function Verify(props) {
    const [ searchParams ] = useSearchParams()
    const { user, updateUser } = useContext(UserContext)

    console.log(user)

    useEffect(() => {
        if (searchParams.get('token') && searchParams.get('projectToken')) {
            localStorage.setItem('token', searchParams.get('token'))
            localStorage.setItem('projectToken', searchParams.get('projectToken'))
            window.location.href = '/make'
        } 
    }, [ searchParams, updateUser])

    return (<></>)
}