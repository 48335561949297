import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Page, H1, HorizonLNB } from 'opize-components';
import { HeaderWrapper } from '../../components';

import Dashboard from './dashboard';
import EmbedRouter from './embed/router'
import OtherEmbedRouter from './other-embed/router'

export default function Router() {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <>
            <HeaderWrapper />
            <Page width={720}>
                <H1>Admin</H1>
                <HorizonLNB exact={false} selected={location.pathname} menu={[
                    { id: "/admin", label: '대시보드', onClick: () => navigate("/admin") },
                    { id: "/admin/embed", label: '임베드', onClick: () => navigate("/admin/embed") },
                    { id: "/admin/other-embed", label: '외부 임베드', onClick: () => navigate("/admin/other-embed") },
                ]} />
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/other-embed/*" element={<OtherEmbedRouter />} />
                    <Route path="/embed/*" element={<EmbedRouter />} />
                </Routes>
            </Page>
        </>
    );
};