import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserContextProvider from './context/user';

import Notion from './screen/notion';
import Verify from './screen/auth/verify';
import Logout from './screen/logout';
import AdminRouter from './screen/admin/router';
import MakeRouter from './screen/make/router';

export default function Router() {
    return (
        <div className="App">
        <BrowserRouter>
          <UserContextProvider>
              <Routes>
                <Route path="/" element={<Notion />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/make/*" element={<MakeRouter />} />
                <Route path="/admin/*" element={<AdminRouter />} />
                <Route path="*" element={<Notion />} />
              </Routes>
          </UserContextProvider>
        </BrowserRouter>
    </div>
    )
}