import { Page, H1, H2, Search, Service } from 'opize-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { instance, instanceWrap } from '../../src/instance'

const Divver = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const EmbedsDiv = styled.div`
    margin-bottom: 50px;
`

const Label = styled.div`
    color: var(--grey6);
    font-size: 14px;
    margin-top: -8px;
`

const OpizeEmbeds = (props) => {
    const { t } = useTranslation('translation')
    const [ otherEmbeds, setOtherEmbeds ] = useState([])
    const [ searchText, setSearchText ] = useState('')

    useEffect(() => {
        (async () => {
            const res = await instanceWrap(() => instance.get('/embed'), t)
            setOtherEmbeds(res.data.embeds)
        })()
    }, [t])

    return (
        <EmbedsDiv>
            <H2>오피즈 임베드</H2>
            <Label>오피즈가 직접 만든 임베드</Label>
            <Divver>
                <Search key="search" value={searchText} onChange={e => setSearchText(e.target.value)} />
                {
                    otherEmbeds.filter(e => {
                        if (searchText === "") return true
                        if (e.name.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.code.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.desc.toUpperCase().includes(searchText.toUpperCase())) return true
                        return false
                    }).map((e, i) => (
                        <Service
                            padding={16}
                            key={i}
                            to={e.url}
                            icon={e.icon}
                            name={e.name}
                            desc={e.desc}
                        />
                    ))
                }
            </Divver>
        </EmbedsDiv>
    )
}

const OtherEmbeds = (props) => {
    const { t } = useTranslation('translation')
    const [ otherEmbeds, setOtherEmbeds ] = useState([])
    const [ searchText, setSearchText ] = useState('')

    useEffect(() => {
        (async () => {
            const res = await instanceWrap(() => instance.get('/other-embed'), t)
            setOtherEmbeds(res.data.otherEmbeds)
        })()
    }, [t])

    return (
        <EmbedsDiv>
            <H2>외부 임베드</H2>
            <Divver>
                <Search key="search" value={searchText} onChange={e => setSearchText(e.target.value)} />
                {
                    otherEmbeds.filter(e => {
                        if (searchText === "") return true
                        if (e.name.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.code.toUpperCase().includes(searchText.toUpperCase())) return true
                        if (e.desc.toUpperCase().includes(searchText.toUpperCase())) return true
                        return false
                    }).map((e, i) => (
                        <Service 
                            padding={16}
                            key={i}
                            to={e.url}
                            icon={e.icon}
                            name={e.name}
                            desc={e.desc}
                        />
                    ))
                }
            </Divver>
        </EmbedsDiv>
    )
}

export default function Make(props) {
    const { t } = useTranslation('translation')

    useEffect(() => {
        document.title = `${t("make")} - Opize Embed`
    }, [t])

    return (
        <Page>
            <H1>{t("greet")}</H1>
            <OpizeEmbeds />
            <OtherEmbeds />
        </Page>
    )
}