import { Header } from 'opize-components'

import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from "react";

import { UserContext } from "../context/user";
import axios from 'axios';

const { REACT_APP_OPIZE_API_SERVER, REACT_APP_PROJECT_CODE, REACT_APP_OPIZE_FRONTEND } = process.env

export function HeaderWrapper(props){
    const { t } = useTranslation('translation')

    const { user, initUser } = useContext(UserContext)
    const [ menus, setMenus ] = useState([
        { label: t('landing'), to: '/' },
    ])
    const [ services, setServices ] = useState({})
    

    useEffect(() => {
        initUser()
    }, [initUser])

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${REACT_APP_OPIZE_API_SERVER}/dashboard`)
            const temp = {};
            Object.values(res?.data?.projects || {})?.forEach(e => {
                temp[e.code] = {
                    label: e.name,
                    img: e.icon,
                    desc: e.desc,
                    to: e.url,
                }
            })
            setServices(temp)
        })()
    }, [])

    useEffect(() => {
        if (user.isVerified) {
            if (user.roles.includes('admin')) {
                setMenus([
                    { label: '운영자', to: '/admin' },
                    { label: t('landing'), to: '/' },
                    { label: t('make'), to: '/make' },
                ])
            } else {
                setMenus([
                    { label: t('landing'), to: '/' },
                    // { label: t('plan'), to: '/plan' },
                    { label: t('make'), to: '/make' },
                ])
            }
        } else {
            setMenus([
                { label: t('landing'), to: '/' },
                // { label: t('plan'), to: '/plan' },
                { label: t('make'), to: '/make' },
            ])
        }
    }, [user, setMenus, t])

    return (
        <Header 
            isLogin={localStorage.getItem('token')}
            app={REACT_APP_PROJECT_CODE}
            projects={services}
            menus={menus}
            user={user}
            userMenus={[
                { label: t('header_user'), to: `${REACT_APP_OPIZE_FRONTEND}/user` },
                { label: t('header_notice'), to: "/notice" },
                { label: t('logout'), to: "/logout" },
            ]}
            loginTo={`${REACT_APP_OPIZE_FRONTEND}/verify-project?projectGet=${REACT_APP_PROJECT_CODE}`}
        />
    )
}